const handleStickyBanner = () => {
  const header = document.querySelector('#site-header');
  const nav = document.querySelector('.sp-internal-nav');
  const banner = document.querySelector('.sticky-banner');
  const stickyCta = document.querySelector('.sticky-cta');

  if (header.classList.contains('scrolling-down')) {
    if (stickyCta) {
      header.style.height = "2.8rem"; 
    }
    if (banner) {
      banner.style.top = "0";
    }
  } else if (header.classList.contains('scrolling-up')) {
    if (banner) {
      banner.style.removeProperty('top');
      banner.style.setProperty('margin-top', '-2px');
    }
    if (stickyCta && window.innerWidth <= 768) {
      header.style.height = "4rem";
    } else if (window.innerWidth > 768) {
      header.style.height = "5rem";
    }
  }
};

window.addEventListener('scroll', handleStickyBanner);